<template>
  <div class="container">
    <div class="row mb-3">
      <div class="col-md-2">
        <a-form-item label="URL">
          <a-input v-model:value="link" size="large" />
        </a-form-item>
      </div>
      <div class="col-md-2">
        <a-form-item label="Логотип" layout="vertical">
          <a-radio-group v-model:value="img" size="large" style="width: 100%">
            <a-radio-button value="https://cdn.multicard.uz/storage/199/9cc595e29c7ff4bc2e15135064b4d.svg" style="line-height: 35px; width: 50%; text-align: center">
              <img src="https://cdn.multicard.uz/storage/199/9cc595e29c7ff4bc2e15135064b4d.svg" width="30" alt="" />
            </a-radio-button>
            <a-radio-button value="https://cdn.multicard.uz/storage/f21/e6fe776f1c07a9b518fe45744b7ed.svg" style="line-height: 35px; width: 50%; text-align: center">
              <img src="https://cdn.multicard.uz/storage/f21/e6fe776f1c07a9b518fe45744b7ed.svg" width="30" alt="" />
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </div>
      <div class="col-md-2">
        <a-form-item label="Цвет">
          <a-input v-model:value="color" size="large" />
        </a-form-item>
      </div>
      <div class="col-md-2">
        <a-form-item label="Формат" class="flex-column align-items-start" label-align="left">
          <a-select v-model:value="format" size="large" style="width: 160px">
            <a-select-option value="svg">SVG</a-select-option>
            <a-select-option value="png">PNG</a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-md-4">
        <a-form-item label="&nbsp;" style="flex-direction: column" :colon="false">
          <a-button :loading="loading" @click="generateQr" size="large" type="primary">
            Создать QR
          </a-button>
        </a-form-item>
      </div>
    </div>
    <div class="flex flex-column" style="gap: 10px">
      <div><img v-if="qr" :src="qr" width="200" /></div>
      <div><a :href="qr" v-if="qr" target="_blank">{{ qr }}</a></div>
      <div><a :href="shortLink" v-if="shortLink" target="_blank">{{ shortLink }}</a></div>
      <a-alert v-if="response" :message="responseTitle" :type="responseType">
        <template #description>
          <pre v-if="responseType === 'info'" :style="{ color: responseColor }">{{ response }}</pre>
          <div v-else>{{ response }}</div>
        </template>
      </a-alert>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import apiClient from '@/services/axios'

let img = ref('https://cdn.multicard.uz/storage/199/9cc595e29c7ff4bc2e15135064b4d.svg'),
  link = ref(''),
  qr = ref(null),
  shortLink = ref(null),
  color = ref('#000000'),
  response = ref(null),
  responseType = ref(''),
  responseColor = ref(''),
  responseTitle = ref(''),
  route = useRoute(),
  format = ref('svg'),
  loading = ref(false);

const generateQr = () => {
  qr.value = null
  loading.value = true
  let params = {
    link: link.value,
    qr: {
      logo: img.value,
      color: color.value,
      format: format.value
    }
  }
  if (route.params.token) {
    params.token = route.params.token
  }
  return apiClient.post('/auth/generate-qr', params).then(data => {
    qr.value = data?.data?.data?.data?.['qr_img']
    shortLink.value = data?.data?.data?.data?.['short_link']
    response.value = data?.data?.data?.data ?? data?.data?.data?.error?.details
    responseType.value = data?.data?.data?.success ? 'info' : 'error'
    responseTitle.value = data?.data?.data?.success ? 'Успешно' : 'Ошибка'
    responseColor.value = data?.data?.data?.success ? '#595c97' : '#d03133'
    return data;
  }).catch(e => {
    response.value = e.error
    responseColor.value = '#d03133'
    responseTitle.value = 'Ошибка'
    responseType.value = 'danger'
  }).finally(() => {
    loading.value = false
  });
}

</script>
